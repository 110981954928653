import React, { useContext, useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import {
  renderThumbMessages,
  renderTrackMessages,
  renderViewMessages,
} from '../scrollbar/Scrollbar';
import SingleMessage from './SingleMessage';

const Messages = ({ ws, roomId }) => {
  const [messages, setMessages] = useState([]);

  const scrollbarsRef = useRef(null);

  useEffect(() => {
    // Call scrollToBottom when the component is mounted or when the messages change
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (!ws) {
      return;
    }
    setMessages([]);
    const handleMessage = (event) => {
      setMessages((prevMessages) => [...prevMessages, JSON.parse(event.data)]);
    };
    ws.addEventListener('message', handleMessage);

    return () => {
      ws.removeEventListener('message', handleMessage);
    };
  }, [ws]);

  let clientJoined = false;
  let started = false;
  let serverJoined = false;

  return (
    <div className="flex flex-col h-[calc(100%-74px)] w-full">
      <h1 className="mb-0 text-xl font-semibold text-gray-900 font-dm dark:text-white">
        Room #{roomId}
      </h1>
      <Scrollbars
        renderTrackVertical={renderTrackMessages}
        renderThumbVertical={renderThumbMessages}
        renderView={renderViewMessages}
        ref={scrollbarsRef}
      >
        {messages.map((m, index) => {
          let starter = 0;
          if(m.name === 'livechat' && !clientJoined) {
            starter = 1;
            clientJoined = true;
          } else if (m.name === 'anonymous' && !serverJoined) {
            starter = 2;
            serverJoined = true;
          }
          return <SingleMessage key={index} message={m} starter={starter}/>
        })}
      </Scrollbars>
    </div>
  );
};

export default Messages;
