import React, { useEffect, useRef } from 'react';

function SingleMessage({ message }) {
  const ref = useRef();

  useEffect(() => {
    // console.log(message)
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [message]);

  const getCurrentDateTime = () => {
    const date = new Date();
   
    const formatter = new Intl.DateTimeFormat(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    });
    return formatter.format(date);
  };

  return (
    <div className="relative max-h-max overflow-hidden pb-1 lg:max-h-[unset] lg:overflow-visible">
      {message?.message && (
        <div className={message.name !== 'User' ? 'flex items-center' : ''}>
          <div
            ref={ref}
            className={`w-full ${message?.name === 'User' ? 'flex flex-col items-end mb-4' : ''
              }`}
          >
            <p
              className={`text-xs font-normal dark:opacity-40 pb-0.5 text-gray-900 dark:text-white`}
            >
              {' '}
              {message.name},{' '}
              {getCurrentDateTime()}{' '}
            </p>
            <div
              className={`mb-2 px-3 py-2 pt-3 max-w-[60%] font-[480] text-gray-900 ${message.name === 'User'
                  ? 'bg-tertiary bg-gradient-to-r from-cyan-500 to-blue-500 w-fit rounded-l-xl rounded-br-xl'
                  : 'bg-gray-100 dark:bg-gray-600/50 w-fit rounded-r-xl rounded-bl-xl'
                }`}
            >
              <p className={`text-base pb-2 dark:text-white ${message.name === 'User' ? 'text-white' : 'text-gray-900'}`}>
                {message?.message}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleMessage;
