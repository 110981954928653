import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter as Router } from 'react-router-dom';
import { AuthContextProvider } from "./components/context/AuthContext";
import { ChatContextProvider } from "./components/context/ChatContext";
import { WebSocketProvider } from "./components/context/WebSocketContext";
import { AuthProvider } from "./context/AuthContext";
import { CookiesProvider } from 'react-cookie';
ReactDOM.render(
  <CookiesProvider>
    <WebSocketProvider>
      <AuthProvider>
        <AuthContextProvider>
          <ChatContextProvider>
            {/* <React.StrictMode> */}
            <App />
            {/* </React.StrictMode> */}
          </ChatContextProvider>
        </AuthContextProvider>
      </AuthProvider>
    </WebSocketProvider>
  </CookiesProvider>,

  document.getElementById('root')
);


