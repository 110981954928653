import { useEffect, useState } from 'react';

import SidebarHorizon from '../components/admin/sidebar';
import Navbar from '../components/admin/navbar';
import { Outlet, useLocation } from 'react-router-dom';
import { FiAlignJustify } from 'react-icons/fi';
import { useAuthCheck } from '../useAuthCheck';
const Admin = () => {
  const [open, setOpen] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const location = useLocation();
  const isChatPage = location.pathname.includes('chat');
  const authUser = useAuthCheck();
    
  useEffect(() => {
    setCurrentUser(authUser);
    window.addEventListener('resize', () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  
  return (
    <div className="flex h-full w-full bg-gray-50 dark:bg-primaryColor">
      {/* <Sidebar open={open} onClose={() => setOpen(false)} /> */}
      <div className={`relative ${open ? 'md:w-[330px]' : 'w-0'}`}>
        <SidebarHorizon open={open} onClose={() => setOpen(false)} />
      </div>

      {/* Navbar & Main Content */}
      <div
        className={`relative ${
          open ? 'md:w-[calc(100vw-330px)] pr-5 w-full' : 'w-full px-3'
        }`}
      >
        {isChatPage ? (
          <div
            className={`bg-whiteColor dark:bg-card p-3 mt-3 rounded-lg ${
              open && 'hidden'
            }`}
          >
            <span
              className="flex text-xl text-gray-600 cursor-pointer dark:text-white xl:hidden "
              onClick={() => setOpen(!open)}
            >
              <FiAlignJustify className="w-5 h-5" />
            </span>
          </div>
        ) : (
          <Navbar onOpenSidenav={() => setOpen(!open)} />
        )}

        <div className={`${isChatPage ? 'mt-3' : 'mt-40 md:mt-32 pb-2.5'}`}>
          <Outlet />
          {/* <DashboardContent /> */}
        </div>
      </div>
    </div>
  );
};
export default Admin;
