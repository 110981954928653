import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="  fixed inset-0 z-50 overflow-auto bg-opacity-50 bg-gray-700 flex items-center justify-center">
      <div className="bg-[#f9fafb] w-11/12 md:w-3/5 mx-auto rounded shadow-lg p-6">
        <button onClick={onClose} className="float-right">
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
