// CategoryContext.js
import { createContext, useContext, useState } from 'react';

const CategoryContext = createContext();

export const useCategory = () => useContext(CategoryContext);

export const CategoryProvider = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState('All');

  const value = {
    selectedCategory,
    setSelectedCategory,
  };

  return <CategoryContext.Provider value={value}>{children}</CategoryContext.Provider>;
};
