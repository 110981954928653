import React, { useState, useEffect } from 'react';
import { useCategory } from './CategoryContext'; // Import useCategory
import RulesetList from './RulesetList';
import LoadingScreen from 'react-loading-screen';

const Rulesets = ({ chatbotId, chatBotName }) => {
  const { selectedCategory } = useCategory(); // Use useCategory
  const [rulesets, setRulesets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchCategories();
  }, [selectedCategory]);

  const fetchCategories = async () => {
    try {
      const response = await fetch("https://policy-api.shopagent.workers.dev/category/list", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ agentId: chatbotId }),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(data)
        setCategories(data);
      } else {
        console.error('Error fetching categories:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  const fetchRulesets = async () => {
    console.log("called")
    try {
      setIsLoading(true);
      const response = await fetch('https://policy-api.shopagent.workers.dev/policy/list_policies', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          agentId: chatbotId, // Replace this with the actual chatbotId
          categoryName: selectedCategory,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data)
        setRulesets(data);
        setIsLoading(false);
      } else {
        console.error('Error fetching rulesets:', response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching rulesets:', error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log(chatbotId)
    console.log(chatBotName)
    fetchRulesets();
  }, [chatbotId, selectedCategory]);

  return (
    <div>
      <h1 className="text-2xl mt-2 ml-6 text-light">Policies</h1>
      {isLoading && <LoadingScreen
        loading={isLoading}
        bgColor={"bg-[#ffffff9e] dark:bg-sidebar/50"}
        spinnerColor=" rgb(9 199 225)"
        textColor="#676767"
      ></LoadingScreen>
      }
      {!isLoading &&
        <RulesetList
          rulesets={rulesets}
          categories={categories}
          chatbotId={chatbotId}
          chatBotName={chatBotName}
          handleRefresh={fetchRulesets}
          refreshCategories={fetchCategories} 
        />
      }

    </div>
  );
};

export default Rulesets;
