import React, { useState, useEffect } from 'react';
import { IoIosArrowForward, IoMdCart, IoMdCash, IoMdRefresh, IoIosListBox } from 'react-icons/io'; // Import the desired icons
import { useCategory } from './CategoryContext'; // Import useCategory
import { FiPlus } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
const Categories = ({ chatbotId, chatBotName }) => {
    const [categories, setCategories] = useState([]);
    const [sCategory, setSCategory] = useState('All');
    const [showModal, setShowModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryState, setNewCategoryState] = useState('active');
    const { setSelectedCategory } = useCategory();
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);


    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const updateSelectedCategory = (updatedCategories) => {
        const activeCategories = updatedCategories.filter((cat) => cat.state === 'active');
        if (activeCategories.length > 0) {
            setSelectedCategory(activeCategories[0].name);
            setSCategory(activeCategories[0].name);
        } else {
            setSelectedCategory(null);
            setSCategory(null);
        }
    };
    const fetchCategories = async () => {
        try {
            const response = await fetch('https://policy-api.shopagent.workers.dev/category/list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ agentId: chatbotId }),
            });

            if (response.ok) {
                const data = await response.json();
                const sortedCategories = data.sort((a, b) => (a.state === 'active' ? -1 : 1));
                // Sorting logic
                setCategories(sortedCategories);
                console.log(categories)
                setIsLoading(false);
            } else {
                console.error('Error fetching categories:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://policy-api.shopagent.workers.dev/category/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    agentId: chatbotId,
                    name: newCategoryName,
                    state: newCategoryState,
                    agentName: chatBotName,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('New category created:', data);
                setCategories((prevCategories) => [...prevCategories, data]);
                // Refresh categories after adding a new one
                // fetchCategories();
            } else {
                console.error('Error creating new category:', response.statusText);
            }
        } catch (error) {
            console.error('Error creating new category:', error);
        }

        setNewCategoryName('');
        setNewCategoryState('active');
        toggleModal();
    };

    useEffect(() => {
        fetchCategories();
    }, [chatbotId, chatBotName]);

    const handleCategoryClick = (event, categoryName) => {
        if (event.target.type === 'checkbox') return;
        setSelectedCategory(categoryName);
        setSCategory(categoryName);
        // console.log(`Rulesets for ${categoryName}:`);

        categories
            .filter((category) => category.name === categoryName)
            .forEach((category) => {
                // console.log(category.rules);
            });
    };

    const handleCheckboxChange = async (event, category) => {
        console.log(event)

        const newState = category.state === 'active' ? 'inactive' : 'active';

        // Update the category state on the server
        try {
            const response = await fetch('https://policy-api.shopagent.workers.dev/category/update-state', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    agentId: chatbotId,
                    updateKey:category.key,
                    name: category.name,
                    state: newState,
                }),
            });

            if (response.ok) {
                // Update the local state if the server update was successful
                const updatedCategories = categories.map((cat) =>
                    cat.name === category.name ? { ...cat, state: newState } : cat,
                );
                setCategories(updatedCategories);
                if (sCategory === category.name && newState === 'inactive') {
                    updateSelectedCategory(updatedCategories);
                }
            } else {
                console.error('Error updating category state:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating category state:', error);
        }
    };
    const Spinner = () => (
        <div className="w-16 h-16 border-t-2 border-blue-500 rounded-full animate-spin mx-auto mt-10"></div>
    );
    return (
        <div>
            <button
                className="flex items-center justify-center mb-4 bg-blue-500 text-white py-2 px-4 rounded"
                onClick={toggleModal}
            >
                <FiPlus className="mr-2" />
                New Category
            </button>
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <h2 className="text-xl mb-4 text-gray-700">Add New Category</h2>
                        <form onSubmit={handleSave}>
                            <label htmlFor="categoryName" className=" text-gray-700 block mb-2">
                                Category Name
                            </label>
                            <input
                                type="text"
                                id="categoryName"
                                className="w-full p-2 border rounded mb-4 text-gray-700"
                                value={newCategoryName}
                                onChange={(e) => setNewCategoryName(e.target.value)}
                            />
                            <label htmlFor="categoryState" className="block mb-2 text-gray-700">
                                State
                            </label>
                            <select
                                id="categoryState"
                                className="w-full p-2 border rounded mb-4 text-gray-700"
                                value={newCategoryState}
                                onChange={(e) => setNewCategoryState(e.target.value)}
                            >
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
                                Save
                            </button>
                            <button
                                type="button"
                                className="ml-2 bg-gray-300 text-black py-2 px-4 rounded"
                                onClick={toggleModal}
                            >
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>
            )}
            {showDeleteModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <h2 className="text-lg mb-4 text-gray-700">
                            Are you sure you want to delete this category?
                        </h2>
                        <div className="flex justify-end">
                            <button
                                className="bg-red-500 text-white py-2 px-4 rounded mr-2"
                                onClick={async () => {
                                    console.log(categoryToDelete)
                                    try {
                                        const response = await fetch(
                                            'https://policy-api.shopagent.workers.dev/category/delete',
                                            {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                },
                                                body: JSON.stringify({
                                                    agentId: chatbotId,
                                                    deleteKey:categoryToDelete.key,
                                                    categoryName: categoryToDelete.name,
                                                    agentName: chatBotName,
                                                }),
                                            },
                                        );

                                        if (response.ok) {
                                            const updatedCategories = categories.filter(
                                                (cat) => cat.name !== categoryToDelete.name,
                                              );
                                              setCategories(updatedCategories);
                                              if (sCategory === categoryToDelete.name) {
                                                updateSelectedCategory(updatedCategories);
                                              }
                                        } else {
                                            console.error(
                                                'Error deleting category:',
                                                response.statusText,
                                            );
                                        }

                                    } catch (error) {
                                        console.error('Error deleting category:', error);
                                    }

                                    setShowDeleteModal(false);
                                    setCategoryToDelete(null);
                                }}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                className="bg-gray-300 text-black py-2 px-4 rounded"
                                onClick={() => {
                                    setShowDeleteModal(false);
                                    setCategoryToDelete(null);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isLoading ? (
                <Spinner />
            ) : (
                <ul className="list-none pl-2">
                    {categories.map((category) => (
                        <li
                            key={category.name}
                            className={`flex items-center justify-between mb-2 cursor-pointer dark:text-light p-2 rounded ${sCategory === category.name ? 'bg-blue-400 text-light dark:bg-blue-400' : 'hover:bg-gray-200 dark:hover:bg-gray-700'
                                } ${category.state === 'inactive' ? 'opacity-50 cursor-not-allowed' : ''}`}
                            onClick={(event) => {
                                if (category.state !== 'inactive') handleCategoryClick(event, category.name);
                            }}
                        >
                            <div className="flex items-center">
                                <span className="ml-2 text-lg">{category.name}</span>
                            </div>
                            <div className="flex items-center">
                                <AiFillDelete
                                    className="mr-2 text-red-500 hover:text-red-700 cursor-pointer"
                                    onClick={() => {
                                        setCategoryToDelete(category);
                                        setShowDeleteModal(true);
                                    }}
                                />
                                <input
                                    type="checkbox"
                                    className="form-checkbox h-4 w-4 mr-2 hover:bg-gray-300 dark:hover:bg-gray-700"
                                    checked={category.state === 'active'}
                                    onChange={(event) => handleCheckboxChange(event, category)}
                                />
                                <IoIosArrowForward />
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Categories;
