import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

function ChatsList(props) {
  const { onStateChange, userId, onOpen } = props;
  const [chats, setChats] = useState([]);
  const { id } = props;

  useEffect(() => {
    // console.log(id.id)
    console.log(userId)
    console.log(id)
    fetch('https://config.shopagent.ai/', {
      method: 'POST',
      body: JSON.stringify({
        operation: 'getAllChatroomIds',
        userId,
        agentId: id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setChats(res);
      });
  }, [props.id]);

  const handleSelect = (chatName, index) => {
    onStateChange(chatName, index + 1);
    onOpen();
  };

  return (
    <>
      {chats.map((chat, index) => (
        <div key={index} onClick={() => handleSelect(chat, index)}>
          <div
            className={`flex w-full items-center gap-3 px-2 ${
              index === chats.length - 1 ? 'pt-3 pb-3' : 'border-b py-3'
            }  cursor-pointer border-gray-200 dark:border-gray-600 lg:cursor-pointer`}
          >
            <div className="relative h-12 w-16 rounded-full">
              <img
                className="w-full h-full rounded-full"
                src={
                  'https://icons.veryicon.com/png/o/miscellaneous/two-color-icon-library/user-286.png'
                }
                alt=""
              />

              <div
                className={`absolute bottom-1 right-1 w-3 h-3 border-[1px] border-white rounded-full ${
                  chat ? 'bg-green-600' : 'bg-red-800'
                }`}
              />
            </div>

            <div className="w-full">
              <div className="flex items-center justify-between w-full">
                <h1 className="text-lg font-normal capitalize text-gray-900 dark:text-white mb-0">
                  Livechat{/* Livechat {index + 1} */}
                </h1>
                {/* <h4 className="text-sm font-medium text-gray-400 ">{time} </h4> */}
              </div>
              <p className="text-base font-normal tracking-normal text-gray-600 dark:text-gray-200">
                {process.env.REACT_APP_NAME}
              </p>
            </div>
          </div>
        </div>
      ))}

      {chats.length === 0 && (
        <div className="text-gray-400 text-lg pt-5 text-center">
          No chat rooms found
        </div>
      )}
    </>
  );
}

export default ChatsList;
