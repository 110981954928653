import Sidebar from '../components/Livechat/Sidebar';
import Chat from '../components/Livechat/Chat';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles.css';

const LiveChat = () => {
  const { currentUser } = useAuth();
  const [name, setName] = useState({});
  const [user, setUser] = useState({});
  const [ws, setWs] = useState(null);
  const [chat, setChat] = useState(false);
  const xyz = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      setName(currentUser.user.first_name);
      setUser(currentUser);
    } else {
      navigate('/login');
    }
  }, [currentUser]);

  useEffect(() => {
    if (chat) {
      const newWs = new WebSocket(`wss://api.shopagent.ai/api/room/${chat}/websocket`);
      newWs.addEventListener('open', () => {
        setWs(newWs);
      });
    }
  }, [chat]);

  useEffect(() => {
    // console.log(name);
  }, [name]);

  useEffect(() => {
    if (ws && chat) {
      // ws.send(JSON.stringify({ name }));
      ws.send(JSON.stringify({ name: 'anonymous' }));
    }
  }, [ws]);

  const handleStateChange = (updatedState) => {
    if (ws) {
      ws.close();
    }
    setChat(updatedState);
  };

  return (
    <div className="home">
      {user.uid && (
        <div className="container-local" style={{ paddingTop: '20px' }}>
          <Sidebar onStateChange={handleStateChange} userId={user.uid} />
          {chat ? (
            <Chat ws={ws} chat={chat} />
          ) : (
            <div className="no-chat-message">Start a new chat</div>
          )}
        </div>
      )}
    </div>
  );
};

export default LiveChat;
