import React, { useEffect, useRef, useState } from "react";
import { auth } from '../../firebase';
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const Message = ({ message }) => {
  const navigate = useNavigate();
  const ref = useRef();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // setCurrentUser(user);
      } else {
        navigate('/login');
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);
  const getTimeFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const formatter = new Intl.DateTimeFormat(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });
    return formatter.format(date);
  };
  return (
    <>
      {message?.message &&
        <div
          ref={ref}
          style={{ width: "99%" }}
          className={`message  ${message.name === "anonymous" && "owner" || message.name === "Agent" && "agent"}`}
        >
          <div className="messageInfo">
            <img
              src="https://img.freepik.com/free-vector/illustration-user-avatar-icon_53876-5907.jpg?w=2000"
              alt=""
            />
            <span>{getTimeFromTimestamp(message?.timestamp)}</span>
          </div>
          <div className="messageContent">
            <p>{message?.message}</p>
          </div>
        </div>
      }
    </>

  );
};

export default Message;
