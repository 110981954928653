import React, { createContext, useEffect, useState } from "react";

const WebSocketContext = createContext();

const WebSocketProvider = ({ children }) => {
  const [ws, setWs] = useState(null);

  useEffect(() => {
    const webSocketURL = "wss://api.shopagent.ai/api/room/6038aaf0ff260a952109b8bbc510009ce3d1060a0c39a487f24b04dd6a4785b2/websocket";
    const newWs = new WebSocket(webSocketURL);

    setWs(newWs);

    return () => {
      if (newWs) {
        newWs.close();
      }
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ ws, setWs }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export { WebSocketContext, WebSocketProvider };