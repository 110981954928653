import React, { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
const Chats = (onChange) => {
  const [chats, setChats] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetch('https://config.shopagent.ai/', {
      method: 'POST',
      body: JSON.stringify({
        operation: 'getAllChatroomIds',
        userId: onChange.userId,
        agentId: id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setChats(res);
      });
  }, []);
  const handleSelect = (chatName) => {
    onChange.onChange(chatName);
  };

  return (
    <div className="chats">
      {chats.map((chat, index) => (
        <div
          className="userChat"
          key={index}
          onClick={() => handleSelect(chat, index)}
        >
          <img
            src="https://icons.veryicon.com/png/o/miscellaneous/two-color-icon-library/user-286.png"
            alt=""
          />
          <div className="userChatInfo">
            <p
              style={{
                width: '350px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '0',
              }}
            >
              Anonymous Customer
            </p>
            <p>
            {process.env.REACT_APP_NAME}{' '}
              <span
                style={{
                  height: '12px',
                  width: '12px',
                  backgroundColor: chat == onChange.chat ? 'red' : '#78B159',
                  borderRadius: '50%',
                  display: 'inline-block',
                }}
              ></span>
            </p>
          </div>
        </div>
      ))}
      {chats.length == 0 ? (
        <div
          style={{
            color: 'white',
            textAlign: 'center',
            fontSize: '12px',
            paddingTop: '10px',
          }}
        >
          No chat rooms found
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Chats;
