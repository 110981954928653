import nft1 from '../assets/NftBanner1.png';
import { NavLink } from 'react-router-dom';

const Banner = () => {
  return (
    <div
      className="flex w-full mb-4 flex-col rounded-2xl bg-cover p-7 md:p-14"
      style={{ backgroundImage: `url(${nft1})` }}
    >
      <div className="w-full">
        <h2 className="mb-2 max-w-full text-xl font-semibold leading-8 text-white md:w-[64%] md:text-4xl md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%]">
          Train {process.env.REACT_APP_NAME} on your brand
        </h2>

        <p className="mb-10 max-w-full text-base font-medium leading-7 text-[#E3DAFF] md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%]">
          In just a few clicks deploy the {process.env.REACT_APP_NAME.toLowerCase()} training process. We'll let
          you know when your agent is ready for you to start testing.
        </p>

        <div className="mt-9 flex items-center justify-between gap-4 sm:justify-start 2xl:gap-10">
          <NavLink
            to="/admin/widgets"
            className="cursor-pointer no-underline	text-black linear rounded-xl bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/70"
          >
            Train now
          </NavLink>

          {/* <NavLink
            to="/admin/about"
            className="text-base font-medium text-white hover:text-gray-200 2xl:ml-2"
          >
            Learn more
          </NavLink> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
