import React from 'react';
import Navbar from './Navbar';
import Search from './Search';
import Chats from './Chats';

const Sidebar = (onChange) => {
  console.log('Sidebar onChange');
  console.log(onChange);
  return (
    <div className="sidebar">
      {/* <Navbar /> */}
      <Search />
      <Chats
        onChange={onChange.onStateChange}
        userId={onChange.userId}
        chat={onChange.chat}
      />
    </div>
  );
};

export default Sidebar;
