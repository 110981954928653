import React, { useState, useEffect, useRef } from 'react';
import Card from '../components/elements/Card';
import Text from '../components/elements/Text';
import { useNavigate, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Select from 'react-select';
import LoadingScreen from "react-loading-screen";
import { TiTick, TiTimes } from "react-icons/ti";
import { AiOutlineMessage, AiOutlineSearch, AiOutlineShop } from "react-icons/ai";
import { ImEmbed } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import { MdModelTraining } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import { useAuthCheck } from '../useAuthCheck';
import { useAuth } from '../context/AuthContext';
import logoImage from '../components/img/bag.png';
const Home = () => {
    const { user } = useAuth();
    const initialFormState =
    {
        name: "",
        description: "",
        openidkey: "",
        agent: "",
        maxtokens: "",
        llmtools: [],
    }
    const initialUrls = ['']
    const navigate = useNavigate();
    const defaultCards = [
        {
            id: 'default',
            name: 'Azule CX - Live',
            description: "Add live chat customer support to any website",
            agent: 'Azule Agent',
            enabled: true,
        },
        {
            id: 'default',
            name: 'Shop Agent - Inactive',
            description: 'A specialized customer support agent for ecommerce. Shop Agent integrates with platforms like Gorgias and Reamaze so it can respond to tickets in those platforms. Shop Agent can take actions like managing subscriptions and looking up orders.',
            agent: 'Shop Agent',
            enabled: false,
        },
        {
            id: 'default',
            name: 'Researcher - Inactive',
            description: 'A task based agent capable of researching on your behalf',
            agent: 'Researcher Agent',
            enabled: false,
        },
    ];
    // States 
    // const [currentUser, setCurrentUser] = useState(useAuthCheck())   
    const [agentOptions, setAgentOptions] = useState([])
    const [agents, setAgents] = useState([])
    const [user_id, setUserId] = useState("")
    const [token, setToken] = useState("")
    const [toolsOptions, setToolsOptions] = useState([])
    const [originalToolsOptions, setOriginalToolsOptions] = useState([])
    const [requiredApiKeys, setRequiredApiKeys] = useState([])
    const [cards, setCards] = useState(defaultCards);
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState(initialFormState);
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [suffix_invalid, setSuffix_invalid] = useState(false);
    const [validated, setValidated] = useState(false);
    const [selectDisplay1, setSelectDisplay1] = useState(true);
    const [selectDisplay2, setSelectDisplay2] = useState(true);
    const [displayTools, setDisplayTools] = useState(true);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false)
    const [deleteKey, setDeleteKey] = useState()
    const [showModalField, setShowModalField] = useState(false)
    const [embedModal, setEmbedModal] = useState(false)
    const [domainModal, setDomainModal] = useState(false)
    const [embedChatId, setEmbedChatId] = useState()
    const [domain, setDomain] = useState("")
    const [widgetUrl, setWidgetUrl] = useState(window.location.origin)
    const [validDomain, setValidDomain] = useState(false);
    const [urls, setUrls] = useState(['']);
    const [shouldProcessCard, setShouldProcessCard] = useState(false);
    const [defaultAgent, setDefaultAgent] = useState("asdasd");
    // Event Handlers
    useEffect(() => {
        const validateDomain = () => {
            const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;
            setValidDomain(regex.test(domain));
        }
        validateDomain();
    }, [domain]);

    const handleAddNew = () => {
        if (urls.length < 5)
            setUrls([...urls, ""]);
    };

    const handleChangeApiKeys = (a) => {
        setFormData({ ...formData, [a.target.name]: a.target.value });
    }

    const handleClose = () => {
        setFormData(initialFormState)
        setDefaultAgent("")
        setShow(false);
    }
    const showEmbedModal = (key) => {
        setEmbedChatId(key)
        setEmbedModal(true)

    };
    const handleDomainChange = (e) => {
        const target = e.target;
        let value = target.value;
        setDomain(value)
    }
    const showDomainModal = (key) => {
        setEmbedChatId(key)
        setDomainModal(true)

    };
    const showDeleteModal = (key) => {
        setDeleteKey(key)
        setDeleteConfirmModal(true)
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.llmtools.length < 1) setSelectDisplay2(false)
        if (formData.agent.length < 1) setSelectDisplay1(false)
        if (formData?.agent?.value === "vector-db-pinecone") setSelectDisplay2(true)
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);

        } else {
            formData.urls = urls
            if (formData.agent_prompt_suffix) {
                if (!(formData.agent_prompt_suffix.includes("{chat_history}") && formData.agent_prompt_suffix.includes("{input}") && formData.agent_prompt_suffix.includes("{agent_scratchpad}"))) {
                    setSuffix_invalid(true)
                    return
                }
            }
            setSuffix_invalid(false)
            setIsLoadingScreen(true)
            if (token) {
                const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/chatbots`, {
                    "method": "POST",
                    "headers": {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        name: formData.name,
                        description: formData.description,
                        maxtokens: formData.maxtokens,
                        openidkey: formData.openidkey,
                        url_summary: "",
                        urls: formData.urls,
                        agent: formData?.agent?.value
                    })
                })
                if (response.ok) {
                    setFormData(initialFormState)
                    setUrls(initialUrls)
                    setRequiredApiKeys([])
                    setShow(false)
                    setValidated(false);
                    getCards()
                    setShouldProcessCard(true);
                    // const configData = {
                    //     namespace: formData.name,
                    //     purpose_summary:    formData.description,
                    // }
                    // console.log(cards)
                    // updateConfig(cards[cards.length - 1].id, configData, user_id);
                    // setIsLoadingScreen(false)
                    // // handleNavigateTrain(cards[cards.length - 1].id, cards[cards.length - 1].name)
                }
                else {
                    setIsLoadingScreen(false)
                    // alert("Error creating chatbot")
                    console.log("Error creating chatbot")
                }
            }
        }
    }
    useEffect(() => {
        async function handlePostResponse() {
            if (shouldProcessCard) {
                const lastCard = cards[cards.length - 1];
                // console.log(lastCard)
                const configData = {
                    namespace: lastCard.name,
                    purpose_summary: lastCard.description,
                    agent: lastCard.agent,
                };
                // console.log(cards);
                await updateConfig(lastCard.id, configData, user_id);
                setIsLoadingScreen(false);
                handleNavigateTrain(lastCard.id, lastCard.name);
                setShouldProcessCard(false);
            }
        }

        handlePostResponse();
    }, [cards]);

    async function updateConfig(chatBotId, configData, userId) {
        const payload = {
            operation: "updateConfig",
            chatBotId,
            configData,
            userId
        };

        const response = await fetch("https://config.shopagent.ai/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });

        if (response.ok) {
            console.log("User configuration updated successfully");
        } else {
            console.error("Error updating user configuration:", response.statusText);
        }
    }

    const handleShow = () => setShow(true);
    const handleShowDefault = (data) => {
        setDefaultAgent(data)
        setFormData({ ...formData, agent: { value: data, label: data, description: data } });
        setShow(true);
    };

    const handleNavigate = (chatbot, chat_bot_id) => {
        // var selected_agent = agents.find(agent => {
        //     return agent.name == chat_data?.agent?.value
        // })
        navigate(`/admin/chat`, { state: { value: chat_bot_id } })
        // navigate('/chat', { state: { value: chat_bot_id, chat_agent: chat_data?.agent?.value, url: selected_agent.url,urls:chat_data?.urls || [''] } })
    };
    const handleNavigateTrain = (chat_bot_id, chat_bot_name) => {
        // var selected_agent = agents.find(agent => {
        //     return agent.name == chat_data?.agent?.value
        // })
        // console.log(chat_bot_id)
        //  navigate(`/live/${chat_bot_id}`)
        navigate(`/admin/train`, { state: { value: chat_bot_id, name: chat_bot_name } })
    };
    const handleDelete = async (chat_bot_id) => {

        // console.log(chat_bot_id)
        setIsLoadingScreen(true)
        //Send to API
        try {
            await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/chatbots?user_id=${user_id}&id=${chat_bot_id}`,
                {
                    method: "DELETE",
                    "headers": {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${user.token}`
                    },
                })
        }
        catch (error) {

        }
        setDeleteConfirmModal(false)
        setIsLoadingScreen(false)
        getCards();

    };

    const handleChange = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value;
        setFormData({ ...formData, [name]: value });
    };

    const shareDomain = () => {
        if (validDomain) {
            fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/domain`, {
                method: "POST",
                body: JSON.stringify({ domain: domain, chat_bot_id: embedChatId }),
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },

            })
                .then((response) => {
                    if (response.status == 200) {
                        // setEmbedChatId(key)
                        setDomainModal(false)
                        setEmbedModal(true)
                        // console.log(window.location.origin)
                    }
                })
        }

    }


    const handleSuffixChange = (event) => {

        const target = event.target;
        const name = target.name;
        let value = target.value;
        if (value) {
            if (!(value.includes("{chat_history}") && value.includes("{input}") && value.includes("{agent_scratchpad}"))) {
                setSuffix_invalid(true)
            }
            else {
                setSuffix_invalid(false)

            }
        }
        else {
            setSuffix_invalid(false)
        }
        setFormData({ ...formData, [name]: value });
    };

    const handleMaxTokens = (event) => {

        const target = event.target;
        const name = target.name;
        let value = target.value;

        if (value < 1) {
            value = 1;
        } else if (value > 6500) {
            value = 6500;
        }
        setFormData({ ...formData, [name]: value });
    };

    const handleChangeSelect = (selectedOptions) => {
        if (selectedOptions.length > 0)
            setSelectDisplay2(true)
        else
            setSelectDisplay2(false)

        const keys = selectedOptions.map(key => ({
            name: key.requiredAPI,
            value: key.requiredAPI,
        }));
        const newKeys = keys
            .filter(({ name }) => name !== null)
            .map(({ name, value }) => ({ name, value }))
        setRequiredApiKeys(newKeys);
        setFormData({ ...formData, llmtools: selectedOptions });
    };

    const handleChangeSelectSingle = (selectedOptions) => {
        if (selectedOptions != "")
            setSelectDisplay1(true)
        setShowModalField(selectedOptions.has_validation)
        if (selectedOptions.value == "vector-db-pinecone") {
            setSelectDisplay2(true)
            setDisplayTools(false)
            setToolsOptions([{
                "value": "Search",
                "label": "Search",
                "id": "serpapi",
                "description": "A search engine. Useful for when you need to answer questions about current events. Input should be a search query.",
                "requiredAPI": [
                    "serpapi_api_key"
                ]
            }])
            setFormData({ ...formData, llmtools: [] })
        } else {
            setDisplayTools(true)
            setToolsOptions(originalToolsOptions)
        }

        setFormData({ ...formData, agent: selectedOptions });
    };

    function getAgents() {
        // console.log("asdasdasd")
        // console.log(process.env.REACT_APP_AGENT_SERVER_URL)
        // fetch(`${process.env.REACT_APP_AGENT_SERVER_URL}/agents`)
        //     .then(res => res.json())
        //     .then(data => {
        //         // console.log(data)
        //         const agents_data = data.map(agent => ({
        //             value: agent.name,
        //             label: `${process.env.REACT_APP_NAME} (beta)`,
        //             description: agent.description,
        //             has_validation: agent.has_validation
        //         }));

        //         setAgents(data)
        //         setAgentOptions(agents_data);
        //     })
        setAgentOptions([{ value: "Chat", label: "Chat", description: "Chat" }, { value: "Research", label: "Research", description: "Research" }])

    }
    function getTools() {
        fetch(`${process.env.REACT_APP_AGENT_SERVER_URL}/tools`)
            .then(res => res.json())
            .then(data => {
                const tools = data.map(tool => ({
                    value: tool.name,
                    label: tool.name,
                    id: tool.id,
                    description: tool.description,
                    requiredAPI: tool.required_api_keys
                }));
                setToolsOptions(tools);
                setOriginalToolsOptions(tools)
            })
    }
    function getCards() {
        if (token) {
            fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/chatbots`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    const newCards = [...cards];
                    if (res && res.length > 0) {
                        newCards[0] = res[0];
                    }
                    setCards(newCards);
                    setIsLoadingScreen(false);
                });
        }
    }
    useEffect(() => {
        if (user) {
            // console.log(user)
            setUserId(user.user.id);
            setToken(user.token);
            getAgents();
            getTools();
            getCards();
        } else {
            navigate("/login");
        }
    }, [token]);
    // console.log(cards)
    return (
        <>
            <section className="text-white pt-0 pb-10" style={{ height: "90vh" }}>
                {isLoadingScreen && <LoadingScreen
                    loading={isLoadingScreen}
                    bgColor={"bg-[#ffffff9e] dark:bg-sidebar/50"}
                    spinnerColor=" rgb(9 199 225)"
                    textColor="#676767"
                ></LoadingScreen>
                }
                {/* <div className="flex flex-row gap-2 justify-center mb-4">
                    <button
                        onClick={() => handleShowDefault("Chat")}
                        className="flex items-center rounded-lg justify-center bg-cyan-600 py-2 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-cyan-900 active:bg-cyan-700 dark:bg-cyan-600 dark:text-white dark:hover:bg-cyan-700 dark:active:bg-cyan-200"
                    >
                        <AiOutlineMessage className="mr-2" />
                        Chat
                    </button>
                    <button
                        onClick={() => handleShowDefault("Research")}
                        className="flex items-center rounded-lg justify-center bg-cyan-600 py-2 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-cyan-900 active:bg-cyan-700 dark:bg-cyan-600 dark:text-white dark:hover:bg-cyan-700 dark:active:bg-cyan-200"
                    >
                        <AiOutlineSearch className="mr-2" />
                        Research
                    </button>
                    <button
                        onClick={() => handleShowDefault("Shop Agent")}
                        className="flex items-center rounded-lg justify-center bg-cyan-200 py-2 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-cyan-200 active:bg-cyan-200 dark:bg-cyan-200 dark:text-white dark:hover:bg-cyan-200 dark:active:bg-cyan-200"
                        disabled
                    >
                        <AiOutlineShop className="mr-2" />
                        Shop Agent
                    </button>
                </div> */}

                <section className="gap-4 grid-cols-1 sm:md:grid sm:md:grid-cols-1 md:xl:grid md:xl:grid-cols-2 xl:2xl:grid xl:2xl:grid-cols-3 auto-rows-fr">
                    {/* <div
                        className={`cursor-pointer bg-[#ffffff9e] dark:bg-sidebar/50 rounded-lg p-4 hover:border hover:border-slate-500 `}
                        // key={index}
                        extra={"flex flex-col w-full relative pt-4 pb-5 px-6 pb-7 h-full"}
                    >
                        <section className="flex justify-center items-center text-center opacity-90">
                            <div>
                                <h3 className="mt-[2px] mb-2.5 ml-px text-lg font-bold text-gray-900 dark:text-white">
                                    Chat Agent
                                </h3>
                                <div className='w-[80px] m-auto'>
                                    <img src={logoImage} alt="Logo" className="text-gray-900 dark:text-white" />
                                </div>
                                <h6 className="mt-[2px] mb-4 ml-px text-sm font-bold text-gray-900 dark:text-white">
                                    Train Azule Agent on your brand
                                    In just a few clicks deploy the azule agent training process. We'll let you know when your agent is ready for you to start testing.
                                </h6>
                                <h5 className="mt-[2px] mb-2.5 ml-px text-lg font-bold text-gray-900 dark:text-white">
                                    {key?.agent}
                                </h5>
                                <div className="flex flex-row gap-2 justify-center mb-4">
                                    <button
                                        onClick={() => handleShowDefault("Chat")}
                                        className="flex items-center rounded-lg justify-center bg-cyan-600 py-2 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-cyan-900 active:bg-cyan-700 dark:bg-cyan-600 dark:text-white dark:hover:bg-cyan-700 dark:active:bg-cyan-200"
                                    >
                                        <AiOutlineMessage className="mr-2" />
                                        Create Chat Agent
                                    </button>
                                </div>

                            </div>
                        </section>


                    </div> */}
                    {cards.map((key, index) => (

                        <div
                            className={`cursor-pointer bg-[#ffffff9e] dark:bg-sidebar/50 rounded-lg p-4 hover:border hover:border-slate-500 `}
                            key={index}
                            extra={"flex flex-col w-full relative pt-4 pb-5 px-6 pb-7 h-full"}
                        >
                            <section className="flex justify-center items-center text-center opacity-90">
                                <div>
                                    <h3 className="mt-[2px] mb-2.5 ml-px text-lg font-bold text-gray-900 dark:text-white">
                                        {key.name}
                                    </h3>
                                    <div className='w-[80px] m-auto'>
                                        <img src={logoImage} alt="Logo" className="text-gray-900 dark:text-white" />
                                    </div>
                                    <h6 className="mt-[2px] mb-4 ml-px text-sm font-bold text-gray-900 dark:text-white">
                                        {key.description}
                                    </h6>
                                    <h5 className="mt-[2px] mb-2.5 ml-px text-lg font-bold text-gray-900 dark:text-white">
                                        {key?.agent}
                                    </h5>

                                    {
                                        key?.id !== "default" &&
                                        <>
                                            <div className="flex w-[60%] items-center justify-center m-auto gap-1 hover:cursor-pointer p-2">
                                                <a
                                                    onClick={() => handleNavigate(key, key.id)}
                                                    className="text-sm font-semibold text-gray-900 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white"
                                                >
                                                    Browse Rooms
                                                </a>
                                                <div className="text-xl font-semibold text-gray-900 transition-all hover:translate-x-1 hover:cursor-pointer dark:text-white">
                                                    <BsArrowRight />
                                                </div>
                                            </div>
                                            < div className='flex flex-row gap-2 pt-4 items-center justify-center'>
                                                {/* <button
                                                    className="items-center  rounded-lg justify-center bg-sky-800 py-1 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-sky-900 active:bg-sky-700 dark:bg-sky-600 dark:text-white dark:hover:bg-sky-700 dark:active:bg-sky-200"
                                                    onClick={() => showDeleteModal(key.id)}
                                                >
                                                    Remove
                                                </button> */}
                                                <button
                                                    className="items-center  rounded-lg justify-center bg-sky-800 py-1 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-sky-900 active:bg-sky-700 dark:bg-sky-600 dark:text-white dark:hover:bg-sky-700 dark:active:bg-sky-200"
                                                    onClick={() => showDomainModal(key.id)}
                                                >
                                                    Code
                                                </button>
                                                <button
                                                    className={`items-center rounded-lg justify-center bg-sky-800 py-1 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-sky-900 active:bg-sky-700 dark:bg-sky-600 dark:text-white dark:hover:bg-sky-700 dark:active:bg-sky-200}`}
                                                    onClick={() => handleNavigateTrain(key.id, key.name)}
                                                    // disabled={!key.enabled}
                                                >
                                                    Training
                                                </button>
                                            </div>
                                        </>
                                    }
                                    {
                                        (key?.id === "default" && key?.agent === "Azule Agent") &&
                                        <div className="flex flex-row gap-2 justify-center mb-4">
                                            <button
                                                onClick={() => handleShowDefault("Azule Agent")}
                                                className="flex items-center rounded-lg justify-center bg-cyan-600 py-2 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-cyan-900 active:bg-cyan-700 dark:bg-cyan-600 dark:text-white dark:hover:bg-cyan-700 dark:active:bg-cyan-200"
                                            >
                                                <AiOutlineMessage className="mr-2" />
                                                Add Azule Agent
                                            </button>
                                        </div>
                                    }
                                    {
                                        (key?.id === "default" && key?.agent === "Shop Agent") &&
                                        <div className="flex flex-row gap-2 justify-center mb-4">
                                             <button
                                                onClick={() => handleShowDefault("Shop Agent")}
                                                className="flex items-center rounded-lg justify-center bg-cyan-200 py-2 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-cyan-200 active:bg-cyan-200 dark:bg-cyan-200 dark:text-white dark:hover:bg-cyan-200 dark:active:bg-cyan-200"
                                                disabled
                                            >
                                                <AiOutlineShop className="mr-2" />
                                                Add Shop Agent
                                            </button>
                                        </div>
                                    }
                                    {
                                        (key?.id === "default" && key?.agent === "Researcher Agent") &&
                                        <div className="flex flex-row gap-2 justify-center mb-4">
                                           
                                           <button
                                                onClick={() => handleShowDefault("Research")}
                                                className="flex items-center rounded-lg justify-center bg-cyan-200 py-2 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-cyan-200 active:bg-cyan-200 dark:bg-cyan-200 dark:text-white dark:hover:bg-cyan-200 dark:active:bg-cyan-200"
                                                disabled
                                            >
                                                <AiOutlineShop className="mr-2" />
                                                Add Research Agent
                                            </button>
                                        </div>
                                    }

                                </div>
                            </section>


                        </div>
                    ))}
                    {/* <div className="cursor-pointer bg-[#ffffff9e] dark:bg-sidebar/50 rounded-lg p-4 hover:border hover:border-slate-500 opacity-100" extra={"flex flex-col w-full relative pt-4 pb-5 px-6 pb-7 h-full"}> */}

                        {/* <div onClick={handleShow} className='flex items-center justify-center h-full w-full'>
                            <div className='w-[100px]'>
                                <svg className='text-gray-900 dark:text-white' stroke="currentColor" fill="currentColor" stroke-width="0" t="1551322312294" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z"></path><path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z"></path></svg>
                            </div>
                        </div> */}


                        <Modal show={deleteConfirmModal} onHide={() => setDeleteConfirmModal(false)} >
                            <Modal.Header closeButton>
                                <Modal.Title className='text-secondary'>Delete Confirmation!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='text-dark'>Oops! Are you sure you want to delete {cards[deleteKey]?.name} chat?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="dark" className='text-dark bg-light' onClick={() => setDeleteConfirmModal(false)}>
                                    <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Cancel</span>
                                    {/* <TiTimes style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '3px', marginTop: '2px' }} /> */}
                                </Button>
                                <Button variant="danger" onClick={() => handleDelete(deleteKey)}>

                                    <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Confirm</span>
                                    <TiTick style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '2px', marginTop: '2px' }} />
                                </Button>
                            </Modal.Footer>

                        </Modal>
                        <Modal size="lg" show={embedModal} onHide={() => setEmbedModal(false)} >
                            <Modal.Header closeButton>
                                <Modal.Title className='text-secondary'>Embed Guide</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='text-dark'>
                                <h5>
                                    Use this CDN before closing {`<head>`} tag<br></br>

                                </h5>
                                {`<script src="${process.env.REACT_APP_CDN_URL}/inject.js" ></script>`}
                                <hr></hr>
                                <Text><h5>
                                    Use this script below before closing {`<body>`} tag <br></br>

                                </h5>


                                </Text>
                                {`<script>`}
                                <Text>
                                    {/* &chatAgent=${cards[embedChatId]?.agent?.value} */}
                                    {` 
                                        window.WebChat.init("${widgetUrl}/demo?chatId=${embedChatId}&userId=${user_id}");
                                    `}

                                </Text>
                                {`</script>`}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" className='text-light ' onClick={() => setEmbedModal(false)}>
                                    <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Close</span>
                                    {/* <TiTimes style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '3px', marginTop: '2px' }} /> */}
                                </Button>
                            </Modal.Footer>

                        </Modal>

                        <Modal size="lg" show={domainModal} onHide={() => setDomainModal(false)} >
                            <Modal.Header closeButton>
                                <Modal.Title className='text-secondary'>Add a live chat widget to your store</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='text-dark'>
                                {/* <Form noValidate validated={validated} onSubmit={handleSubmit} > */}
                                <FloatingLabel
                                    controlId="floatingTextarea1"
                                    label="Enter Domain"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        name="name"
                                        type="text"
                                        required
                                        placeholder="Enter Domain"
                                        value={domain}
                                        onChange={handleDomainChange}
                                    // autoFocus
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Domain is required
                                    </Form.Control.Feedback>

                                </FloatingLabel>
                                {!validDomain &&
                                    (<Form.Label className={`invalid-feedback-select`}>Please enter a valid domain</Form.Label>)}
                                {/* </Form> */}

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" className='text-light ' onClick={() => shareDomain()}>
                                    <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Submit</span>
                                    {/* <TiTimes style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '3px', marginTop: '2px' }} /> */}
                                </Button>
                                <Button variant="danger" className='text-light ' onClick={() => setDomainModal(false)}>
                                    <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Close</span>
                                    {/* <TiTimes style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '3px', marginTop: '2px' }} /> */}
                                </Button>
                            </Modal.Footer>

                        </Modal>
                    {/* </div> */}
                    {
                        <Modal className="modal fade" show={show} onHide={handleClose} dialogClassName="modal-90w">
                            <Modal.Header closeButton>
                                <Modal.Title>Add New Agent</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form noValidate validated={validated} onSubmit={handleSubmit} >
                                    <FloatingLabel
                                        controlId="floatingTextarea1"
                                        label="Name"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            required
                                            placeholder="Name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        // autoFocus
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Name is required
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel
                                        controlId="floatingTextarea2"
                                        label="Description"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            required
                                            placeholder="Description"
                                            value={formData.description}
                                            onChange={handleChange}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Description is required
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                    {showModalField &&
                                        <FloatingLabel
                                            controlId="floatingTextarea3"
                                            label="OpenAI API Key"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                name="openidkey"
                                                type="text"
                                                required
                                                placeholder="OpenAI API Key"
                                                value={formData.openidkey}
                                                onChange={handleChange}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                OpenID key is required.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    }
                                    {showModalField &&
                                        <FloatingLabel
                                            controlId="floatingTextarea3"
                                            label="Maximum Tokens"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                name="maxtokens"
                                                type="number"
                                                placeholder="Maximum Tokens"
                                                value={formData.maxtokens}
                                                onChange={handleMaxTokens}

                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                            Maximum Tokens is Required.
                                        </Form.Control.Feedback> */}
                                        </FloatingLabel>
                                    }
                                    {
                                        showModalField &&
                                        <Form.Group className="mb-3" controlId="floatingTextarea4">
                                            <Form.Label>Agents</Form.Label>
                                            <Select
                                                defaultValue={[]}
                                                name="agent"
                                                options={agentOptions}
                                                className="basic-single-select"
                                                classNamePrefix="select"
                                                value={formData.agent || defaultAgent}
                                                onChange={handleChangeSelectSingle}
                                                required
                                                isDisabled={!!defaultAgent}
                                            />
                                            {!selectDisplay1 &&
                                                (<Form.Label className={`invalid-feedback-select`}>Please Select an Agent</Form.Label>)}
                                        </Form.Group>
                                    }
                                    {
                                        showModalField &&
                                        <Button
                                            style={{ marginTop: "10px", marginBottom: "15px", border: "none" }}
                                            className="font-semibold rounded-lg py-2 px-10 bg-info"
                                            variant="dark"
                                            onClick={handleAddNew}
                                        >
                                            <IoIosAddCircleOutline style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '5px', marginTop: '2px' }} />
                                            <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Add New</span>

                                        </Button>
                                    }

                                    {urls.map((url, index) => (
                                        <div key={index} className="p-2 d-flex mb-3 align-items-center w-100" style={{ width: "100%" }}>
                                            <div style={{ width: "80%" }}>
                                                {
                                                    showModalField &&
                                                    <FloatingLabel
                                                        key={index}
                                                        controlId={`url${index}`}
                                                        label={`URL ${index + 1}`}
                                                        className="w-100 me-3"
                                                    >
                                                        <Form.Control
                                                            name={`url${index}`}
                                                            type="text"
                                                            placeholder="Enter URL"
                                                            required
                                                            value={url}
                                                            onChange={(e) => {
                                                                const newUrls = [...urls];
                                                                newUrls[index] = e.target.value;
                                                                setUrls(newUrls);
                                                            }}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {`URL ${index + 1}`} is required.
                                                        </Form.Control.Feedback>

                                                    </FloatingLabel>
                                                }

                                            </div>
                                            <div style={{ width: "20%" }}>
                                                {
                                                    showModalField &&
                                                    <Button
                                                        style={{ marginLeft: "15px" }}
                                                        // className="mb-3"
                                                        variant="danger"
                                                        onClick={() => {
                                                            const newUrls = [...urls];
                                                            newUrls.splice(index, 1);
                                                            setUrls(newUrls);
                                                        }}
                                                    >
                                                        <RxCross1 />
                                                    </Button>
                                                }

                                            </div>
                                        </div>
                                    ))}
                                    {showModalField &&
                                        <Form.Group className="mb-3" controlId="floatingTextarea5">
                                            <Form.Label>LLM Tools</Form.Label>
                                            <Select
                                                defaultValue={[]}
                                                isMulti
                                                name="llmtools"
                                                options={toolsOptions}
                                                className="basic-multi-select border-red"
                                                classNamePrefix="select"
                                                required
                                                value={formData.llmtools}
                                                onChange={handleChangeSelect}
                                            />
                                            {!selectDisplay2 &&
                                                (<Form.Label className={`invalid-feedback-select`}>Please Select LLM Tools</Form.Label>)}
                                        </Form.Group>
                                    }

                                    {!displayTools &&
                                        <>
                                            <FloatingLabel
                                                controlId="floatingTextarea3"
                                                label="Pinecone API Key"
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    name="pine_cone_api_key"
                                                    type="text"
                                                    required
                                                    placeholder="Pinecone API Key"
                                                    value={formData.pine_cone_api_key}
                                                    onChange={handleChange}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    OpenAI API Key is required.
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                            <FloatingLabel
                                                controlId="floatingTextarea3"
                                                label="Pinecone Environment"
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    name="enivornment"
                                                    type="text"
                                                    required
                                                    placeholder="Pinecone environment"
                                                    value={formData.enivornment}
                                                    onChange={handleChange}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Pinecone environment is required.
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                            <FloatingLabel
                                                controlId="floatingTextarea3"
                                                label="Pinecone Index Name"
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    name="index_name"
                                                    type="text"
                                                    required
                                                    placeholder="Pinecone index name"
                                                    value={formData.index_name}
                                                    onChange={handleChange}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Index name is required.
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                            <FloatingLabel
                                                controlId="floatingTextarea3"
                                                label="Pinecone Data Key"
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    name="text_key"
                                                    type="text"
                                                    required
                                                    placeholder="Pinecone Data Key"
                                                    value={formData.text_key}
                                                    onChange={handleChange}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Pinecone Data Key is required.
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                            <FloatingLabel
                                                controlId="floatingTextarea3"
                                                label="Pinecone Tool Name"
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    name="pine_cone_tool_name"
                                                    type="text"
                                                    placeholder="Pinecone API Key"
                                                    value={formData.pine_cone_tool_name}
                                                    onChange={handleChange}

                                                />
                                            </FloatingLabel>
                                            <FloatingLabel
                                                controlId="floatingTextarea3"
                                                label="Pinecone Tool Description"
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    name="pine_cone_tool_description"
                                                    type="text"
                                                    placeholder="Pinecone Tool Description"
                                                    value={formData.pine_cone_tool_name}
                                                    onChange={handleChange}

                                                />
                                            </FloatingLabel>
                                        </>

                                    }
                                    {showModalField && <FloatingLabel
                                        controlId="floatingTextarea3"
                                        label="Agent Prompt prefix"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            name="agent_prompt_prefix"
                                            type="text"
                                            placeholder="Agent Prompt prefix"
                                            value={formData.agent_prompt_prefix}
                                            onChange={handleChange}

                                        />
                                    </FloatingLabel>
                                    }
                                    {showModalField && <FloatingLabel
                                        controlId="floatingTextarea3"
                                        label="Agent Prompt suffix"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            name="agent_prompt_suffix"
                                            type="text"
                                            isValid={!suffix_invalid}
                                            isInvalid={suffix_invalid}
                                            placeholder="Agent Prompt suffix"
                                            value={formData.agent_prompt_suffix}
                                            onChange={handleSuffixChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Invalid prompt suffix.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                    }

                                    {showModalField && <p style={{ fontSize: "11px" }}>Note: Suffix should contain chat_history, input and agent_scratchpad variables</p>}
                                    {showModalField && <p style={{ fontSize: "11px" }}>{"E.g: Begin!{chat_history} Question: {input} {agent_scratchpad}"}</p>}

                                    {requiredApiKeys.map((entity, index) => (
                                        <ApiField onChange={handleChangeApiKeys} key={index} entity={entity} />
                                    ))}

                                    <section className='modal-footer'>
                                        <Button className="font-semibold rounded-lg py-2 px-10 bg-light text-dark" variant="dark" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button className="font-semibold rounded-lg py-2 px-10" variant="danger" type="submit" >
                                            Save Changes
                                        </Button>

                                    </section>


                                </Form>
                            </Modal.Body>
                            {/* <Modal.Footer>
                            <Button className={"btn-btn"} variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="success" onClick={handleSubmit}>
                                Save Changes
                            </Button>
                        </Modal.Footer> */}
                        </Modal>
                    }

                </section >

                <style jsx>{`
                
                @media (max-width: 768px) {
                    .grid {
                        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    }
                }
                @media (max-width: 480px) {
                    .grid {
                        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                    }
                }
            `}</style>
            </section >
        </>
    );
};
const ApiField = ({ entity, onChange }) => {
    return (
        <div>
            {
                entity.name.map((message, index) => (
                    <ApiField1 onChange={onChange} key={index} message={message} />
                ))
            }
        </div>
    )
}

const ApiField1 = ({ message, onChange }) => {

    return (

        <FloatingLabel
            controlId={`floatingTextarea`}
            label={message}
            className="mb-3"
        >
            <Form.Control
                name={message}
                type="text"
                required
                placeholder={message}
                value={message.value}
                onChange={onChange}
            />
            <Form.Control.Feedback type="invalid">
                {message} is required
            </Form.Control.Feedback>
        </FloatingLabel>
    )
}

export default Home;