import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { BsArrowBarUp } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import { FiAlignJustify } from 'react-icons/fi';
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from 'react-icons/io';

import Dropdown from '../dropdown';
import navbarimage from '../assets/Navbar.png';

import avatar from '../assets/avatar4.jpg';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';

const Navbar = ({ onOpenSidenav }) => {
  const [activeTab, setActiveTab] = useState('Widgets');
  const [darkmode, setDarkmode] = useState(
    document.body.classList.contains('dark')
  );
  const location = useLocation();
  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirect to the login page after logging out
  };

  useEffect(() => {
    if (location.pathname.includes('dashboard')) {
      setActiveTab('Main Dashboard');
    }
    if (location.pathname.includes('chat')) {
      setActiveTab('Live Chat');
    }
    if (location.pathname.includes('train')) {
      setActiveTab('Agent Training');
    }
    if (location.pathname.includes('widget')) {
      setActiveTab('Widgets');
    }
    if (location.pathname.includes('about')) {
      setActiveTab('About Us');
    }
  }, [location.pathname]);
  // Add a conditional check to see if the route is for Agent Training
  if (location.pathname.includes('train')) {
    return null;
  }
  return (
    <nav
      w={{
        base: 'calc(100vw - 6%)',
        md: 'calc(100vw - 8%)',
        lg: 'calc(100vw - 6%)',
        xl: 'calc(100vw - 350px)',
        '2xl': 'calc(100vw - 365px)',
      }}
      className="fixed top-3 right-3 lg:right-4 lg:top-5 z-40 flex w-[calc(100vw-40px)] lg:w-[calc(100vw-355px)] flex-row flex-wrap items-center justify-between rounded-xl bg-[#ffffff9e] dark:bg-sidebar/50 p-2 backdrop-blur-xl"
    >
      <div className="mt-1 ml-1">
        <p className="text-2xl px-4 font-semibold text-gray-900 capitalize shrink dark:text-white">
          {activeTab}
        </p>
      </div>

      <div className="relative flex items-center justify-around flex-grow h-16 gap-2 px-2 py-2 mt-1 rounded-full shadow-xl w-96 bg-whiteColor dark:bg-sidebar shadow-gray-200 dark:shadow-none md:flex-grow-0 md:gap-1 xl:gap-2">
        <div className="flex h-full items-center rounded-full bg-gray-100 dark:bg-card xl:w-[225px]">
          <p className="pl-3 pr-2 mb-0 text-xl">
            <FiSearch className="w-4 h-4 text-gray-400" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block border-none h-full w-full rounded-full bg-gray-100 dark:bg-card text-sm font-normal text-slate-600 outline-none placeholder:!text-gray-500 dark:text-white dark:placeholder:!text-gray-500 sm:w-fit"
          />
        </div>

        <span
          className="flex text-xl text-gray-600 cursor-pointer dark:text-white xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="w-5 h-5" />
        </span>

        {/* start Notification */}
        <Dropdown
          button={
            <p className="mb-0 cursor-pointer">
              {/* <IoMdNotificationsOutline className="w-4 h-4 text-gray-600 dark:text-white" /> */}
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-whiteColor p-4 shadow-xl shadow-gray-200 dark:!bg-sidebar dark:text-white dark:shadow-none sm:w-[460px]">
              <div className="flex items-center justify-between">
                <p className="text-base font-semibold text-gray-900 dark:text-white">
                  Notification
                </p>
                <p className="text-sm font-semibold text-gray-900 dark:text-white">
                  Mark all read
                </p>
              </div>

              <button className="flex items-center w-full">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="flex flex-col justify-center w-full h-full px-1 ml-2 text-sm rounded-lg">
                  <p className="mb-1 text-base font-semibold text-left text-gray-900 dark:text-white">
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className="text-xs text-left text-gray-900 font-base dark:text-white">
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>

              <button className="flex items-center w-full">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="flex flex-col justify-center w-full h-full px-1 ml-2 text-sm rounded-lg">
                  <p className="mb-1 text-base font-semibold text-left text-gray-900 dark:text-white">
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className="text-xs text-left text-gray-900 font-base dark:text-white">
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>
            </div>
          }
          classNames={'py-2 top-10 -left-[230px] md:-left-[440px] w-max'}
        />

        {/* start Horizon PRO */}
        <Dropdown
          button={
            <p className="mb-0 cursor-pointer">
              {/* <IoMdInformationCircleOutline className="w-4 h-4 text-gray-600 dark:text-white" /> */}
            </p>
          }
          children={
            <div className="flex w-96 flex-col gap-2 rounded-2xl bg-whiteColor p-4 shadow-xl shadow-gray-200 dark:!bg-sidebar dark:text-white dark:shadow-none">
              <div
                style={{
                  backgroundImage: `url(${navbarimage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
                className="w-full mb-2 rounded-lg aspect-video"
              />
              <a
                target="blank"
                href="https://horizon-ui.com/pro?ref=live-pro-tailwind-react"
                className="px-full linear flex cursor-pointer items-center justify-center rounded-xl bg-brand-500 py-[11px] font-semibold text-white transition duration-200 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Buy Horizon UI PRO
              </a>
              <a
                target="blank"
                href="https://horizon-ui.com/docs-tailwind/docs/react/installation?ref=live-pro-tailwind-react"
                className="px-full linear flex cursor-pointer items-center justify-center rounded-xl border py-[11px] font-semibold text-gray-900 transition duration-200 hover:bg-gray-200 hover:text-gray-900 dark:!border-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-white/10"
              >
                See Documentation
              </a>
              <a
                target="blank"
                href="https://horizon-ui.com/?ref=live-pro-tailwind-react"
                className="hover:bg-black px-full linear flex cursor-pointer items-center justify-center rounded-xl py-[11px] font-semibold text-gray-900 transition duration-200 hover:text-gray-900 dark:text-white dark:hover:text-white"
              >
                Try Horizon Free
              </a>
            </div>
          }
          classNames={'py-2 top-10 -left-[250px] md:-left-[330px] w-max'}
          animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
        />

        {/* Dark Mode toggle */}
        <div
          className="text-gray-600 cursor-pointer"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove('dark');
              setDarkmode(false);
            } else {
              document.body.classList.add('dark');
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="w-4 h-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="w-4 h-4 text-gray-600 dark:text-white" />
          )}
        </div>

        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="w-10 h-10 rounded-full"
              src={avatar}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-whiteColor bg-cover bg-no-repeat pb-4 shadow-xl shadow-gray-200 dark:!bg-sidebar dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-semibold text-gray-900 dark:text-white">
                    👋 Hey, {user?.user?.first_name}
                  </p>{' '}
                </div>
              </div>
              <div className="w-full h-px mt-3 bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col mt-3 ml-4">
                <NavLink
                  to="/admin/profile"
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </NavLink>
                {/* <a
                  href=" "
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Newsletter Settings
                </a> */}
                <a
                  onClick={handleLogout}
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </a>
              </div>
            </div>
          }
          classNames={'py-2 top-10 -left-[180px] w-max'}
        />
      </div>
    </nav>
  );
};

export default Navbar;
