import React from 'react';
import { MdHub } from 'react-icons/md';
import { MdElectricCar } from 'react-icons/md';
import { GiGraduateCap } from 'react-icons/gi';
import CardMenu from '../card/CardMenu';
import Card from '../card';
import Transaction from '../dataDisplay/Transaction';

function Balance() {
  return (
    <Card extra={'p-3 h-full'}>
      <div className="mt-[7px] flex flex-col items-center rounded-xl px-3 py-4 font-semibold text-gray-900 dark:text-white">
        <h2 className="text-xl font-semibold mb-4">Thanks for being early to {process.env.REACT_APP_NAME}!</h2>
        <p className="text-base font-medium text-center mb-4">
          There is going to be some cool stuff here soon. For now, if you'd like to stay updated on {process.env.REACT_APP_NAME} updates please join our Slack.
        </p>
        <a
          href="https://join.slack.com/t/shopagentworkspace/shared_invite/zt-1s5a9rowk-J9heg5neX5hSDkaTO1vcBQ"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-600 text-white px-6 py-2 rounded-md text-sm font-semibold hover:bg-blue-700"
        >
          Join Slack
        </a>
      </div>
    </Card>
  );
}

export default Balance;
