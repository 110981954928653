import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';

import { useContext } from 'react'; // Import useContext
import { useAuth } from '../../../context/AuthContext'; // Import AuthContext

import '../../../styles.css';

import Card from '../card';
import ChatsList from './ChatsList';
import SingleChat from './SingleChat';
import Search from './Search';

const LiveChat = ({ }) => {
  const [name, setName] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [ws, setWs] = useState(null);
  const [chat, setChat] = useState(false);
  const [cards, setCards] = useState({});
  const [botId, setBotId] = useState("");
  const [roomId, setRoomId] = useState(0)
  const [isLoading, setIsLoading] = useState(true);

  const xyz = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const { user } = useAuth(); // Use AuthContext

  const handleSelect = (e) => {
    console.log(e.target.value)
    setBotId(e.target.value);
  };

  useEffect(() => {
    if (xyz.state?.value) {
      console.log(xyz.state?.value);
      setBotId(xyz.state?.value);
    } else if (cards && Object.keys(cards).length > 0) {
      const firstBotId = Object.keys(cards)[0];
      setBotId(cards[firstBotId]?.id);
    }
  }, [xyz.state, cards]);

  useEffect(() => {
    if (user) { // Use currentUser from AuthContext
      // console.log(currentUser);
      setName(user.user.first_name);
      setCurrentUser(user);
      getCards();
    } else {
      navigate('/login');
    }
  }, [currentUser]);

  useEffect(() => {
    if (chat) {
      const newWs = new WebSocket(
        `wss://api.shopagent.ai/api/room/${chat}/websocket`
      );
      newWs.addEventListener('open', () => {
        setWs(newWs);
      });
    }
  }, [chat]);


  useEffect(() => {
    if (ws && chat) {
      // ws.send(JSON.stringify({ name }));
      ws.send(JSON.stringify({ name: 'anonymous' }));
    }
  }, [ws]);

  const handleStateChange = (updatedState, roomId) => {
    if (ws) {
      ws.close();
    }
    setChat(updatedState);
    setRoomId(roomId);
  };
  function getCards() {
    fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/chatbots`, {
      method: "GET",
      "headers": {
        "Authorization": `Bearer ${user.token}`,
      },
    })
      .then(res => res.json())
      .then(res => {
        setCards(res || {});
        setIsLoading(false);
      });
  }
  const Spinner = () => (
    <div className="w-16 h-16 border-t-2 border-blue-500 rounded-full animate-spin mx-auto mt-10"></div>
  );
  return (
    <>

      {currentUser?.user?.id && (
        <div className="flex w-full grid-cols-10 flex-col gap-2 h-[96.5vh] lg:flex-row">
          {/* Sidebar for Chat List */}
          <div className="w-full lg:w-[35%]">
            <Card extra={'w-full h-full p-4'}>
              {isLoading ? (
                <Spinner />
              ) : (<>
                <h1 className="mb-3 text-xl font-semibold text-gray-900 font-dm dark:text-white">
                  Your Chat Bots
                </h1>
                <div className="flex items-center justify-center">
                  <select onChange={handleSelect} className="mb-3 mr-2 w-full rounded-full border flex items-center justify-center text-sm font-semibold border-none text-gray-600 hover:cursor-pointer dark:!bg-sidebar dark:text-white">

                    {Object.keys(cards).map(id => (
                      <option value={cards[id]?.id} key={id}>{' #' + cards[id]?.id + " " + cards[id]?.name}</option>
                    ))}
                  </select>
                </div>

                <h1 className="mb-4 mt-2 text-xl font-semibold text-gray-900 font-dm dark:text-white">
                  Chat Rooms
                </h1></>
              )}

              {/* <Search /> */}

              <div className="mt-1 overflow-y-auto">
                <ChatsList
                  onStateChange={handleStateChange}
                  userId={currentUser?.user?.id}
                  chat={chat}
                  onOpen={() => setOpen(true)}
                  id={botId}
                />
              </div>
            </Card>
          </div>

          <div className="w-full lg:w-[64.5%]">
            <SingleChat
              ws={ws}
              chat={chat}
              open={open}
              onClose={() => setOpen(false)}
              roomId={roomId}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LiveChat;
