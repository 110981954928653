import React, { useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdInsertLink, MdClose } from 'react-icons/md';
import { IoHelpCircleOutline } from 'react-icons/io5';
import URLInput from './URLInput';
import HelpDeskForm from './HelpDeskForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../context/AuthContext'; // Import AuthContext
function AddResourceModal({ onClose, isOpen, onAddResource, chatBotId, chatBotName }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showURLInput, setShowURLInput] = useState(false);
  const [showHelpDeskForm, setShowHelpDeskForm] = useState(false);
  const [fileCategory, setFileCategory] = useState("");
  const { user } = useAuth(); // Use AuthContext
  if (!isOpen) {
    return null;
  }
  const handleFileSubmit = async () => {
    if (!selectedFile || !fileCategory) { // Update this line
      return;
    }

    const formData = new FormData();
    formData.append('files', selectedFile);
    onClose();
    toast.success('Training Resource Queued. It will show up in the resources panel once the training is complete.', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
    });
    const createCategoryResponse = await fetch('https://policy-api.shopagent.workers.dev/category/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        agentId: chatBotId,
        name: fileCategory,
        state: "inactive",
        agentName: chatBotName,
      }),
    });

    if (!createCategoryResponse.ok) {
      console.error('Error creating new category:', createCategoryResponse.statusText);
    } else {
      console.log('New category created:', await createCategoryResponse.json());
    }
    // Log the contents of formData
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    try {
      const response = await fetch(`https://lighthouse.shopagent.workers.dev?chatBotId=${chatBotId}&chatBotName=${chatBotName}&category=${fileCategory}`, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'multipart/form-data',
          // Authorization:
          //   'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
        },
        body: formData,
      });

      if (response.ok) {
        // const responseData = await response.json();
        console.log(response);
        onAddResource();
      } else {
        console.error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      // console.log(data);

      // Reset the state
      setSelectedFile(null);
      setFileCategory("");

      // Close the modal
      onClose();
    } catch (error) {
      console.error('Error uploading the file:', error);
    }
  };

  const handleURLSubmit = async (data) => {
    // Send a request to the API for each URL
    // Close the modal
    onClose();
    toast.success('Training Resource Queued. It will show up in the resources panel once the training is complete.', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
    });

    data.urls.forEach(async (url, index) => {
      try {
        const category = data.categories[index];

        // Create category
        const createCategoryResponse = await fetch('https://policy-api.shopagent.workers.dev/category/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            agentId: chatBotId,
            name: category,
            state: "inactive",
            agentName: chatBotName,
          }),
        });

        if (!createCategoryResponse.ok) {
          console.error('Error creating new category:', createCategoryResponse.statusText);
        } else {
          console.log('New category created:', await createCategoryResponse.json());
        }

        // Fetch URL
        const response = await fetch(`https://lighthouse.shopagent.workers.dev?targetUrl=${url}&chatBotId=${chatBotId}&chatBotName=${chatBotName}&category=${category}`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
            // other headers
          },
        });

        if (response.ok) {
          console.log(response);
          onAddResource();
        } else {
          console.error(`Error: ${response.status} ${response.statusText}`);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    });



  }
  const handleHelpDeskSubmit = async (data) => {
    console.log(user)
    if (data.enableCheckbox === true) {
      const requestBody = {
        // namespace: chatBotName,
        brand_name: data.apiUrl,
        username: data.email,
        api_key: data.apiKey,
        gorgias_enabled: data.enableCheckbox,
        // months: data.months,
      };
      try {
        const response = await fetch(`https://management-api.shopagent.ai/chatbots/updateconfig?id=${chatBotId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify(requestBody),
        });

        if (response.ok) {
          const responseData = await response.json();
          console.log(responseData);
        } else {
          console.error(`Error: ${response.status} ${response.statusText}`);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
    // onAddResource(dataToResourceAPI)
    onClose();
    // toast.success('Training Resource Queued', {
    //   position: toast.POSITION.TOP_RIGHT,
    //   autoClose: 5000,
    // });
    // Replace with actual values
    // const chat_bot_id = chatBotId;


    // const months = 4;
    // const requestBody = {
    //   namespace: chatBotName,
    //   brand: data.apiUrl,
    //   email: data.email,
    //   apiKey: data.apiKey,
    //   chat_bot_id: chatBotId,
    //   months: data.months,
    // };
    // const months = 4;

    // try {
    //   const response = await fetch('https://gorgias-producer.shopagent.workers.dev/', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(requestBody),
    //   });

    //   if (response.ok) {
    //     const responseData = await response.json();
    //     // console.log(responseData);
    //   } else {
    //     console.error(`Error: ${response.status} ${response.statusText}`);
    //   }
    // } catch (error) {
    //   console.error('Error:', error);
    // }


  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setShowURLInput(false);
    setShowHelpDeskForm(false);

  };

  const handleURLButtonClick = () => {
    setShowURLInput(true);
    setShowHelpDeskForm(false);
    setSelectedFile(null)
  };
  const handleHelpDeskButtonClick = () => {
    setShowHelpDeskForm(true);
    setShowURLInput(false);
    setSelectedFile(null)
  };



  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
      <ToastContainer />
      <div className="bg-light w-full sm:w-4/5 md:w-3/5 lg:w-1/2 xl:w-2/5 p-6 my-8 mx-4 rounded shadow-lg">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-2xl mb-0 text-gray-700">Add a new resource</h2>
          <button onClick={onClose} className="text-gray-700">
            <MdClose size={24} />
          </button>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <label className="bg-blue-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded flex flex-col items-center justify-center cursor-pointer">
            <input
              type="file"
              className="hidden"
              accept=".txt,.docx,.csv"
              onChange={handleFileChange}
            />
            <AiOutlineCloudUpload className="text-4xl" />
            <span>File Upload</span>
          </label>
          <button
            className="bg-blue-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded flex flex-col items-center justify-center"
            onClick={handleURLButtonClick}
          >
            <MdInsertLink className="text-4xl" />
            <span>URL</span>
          </button>
          <button
            className="bg-blue-200  text-white font-bold py-2 px-4 rounded flex flex-col items-center justify-center"
            onClick={handleHelpDeskButtonClick}
            disabled
          >
            <IoHelpCircleOutline className="text-4xl" />
            <span>Helpdesk</span>
          </button>
        </div>
        {selectedFile && (
          <div className="mt-4 text-gray-700">
            Selected file: {selectedFile.name}
            <input // Add the input field for the category
              className="border border-gray-300 ml-4 px-2 py-1 rounded"
              type="text"
              placeholder="Category"
              value={fileCategory}
              onChange={(e) => setFileCategory(e.target.value)}
            />
            <button
              className={`bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded ml-4 ${!fileCategory ? "opacity-50 cursor-not-allowed" : ""
                }`}
              onClick={handleFileSubmit}
              disabled={!fileCategory}
            >
              Submit
            </button>
          </div>
        )}
        {showURLInput && (
          <div className="mt-4">
            <URLInput onSubmit={handleURLSubmit} />
          </div>
        )}
        {showHelpDeskForm &&
          <div className="mt-4">
            <HelpDeskForm onSubmit={handleHelpDeskSubmit} />
          </div>

        }
      </div>
    </div>
  );
}

export default AddResourceModal;
