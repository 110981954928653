import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';

// import Sidebar from './components/widgets/Sidebar';
import Home from './page/Widgets';
import Signup from './page/Signup';
import ForgetPassword from './page/ForgetPassword';
import ConfirmForgetPassword from './page/ConfirmForgetPassword';
import Login from './page/Login';
import LiveChat from './page/LiveChat';
import Profile from './page/Profile';
import About from './page/About';
import Demo from './page/Demo';
import './App.css';

// import Navbar from './components/widgets/Navbar';
import Layout from './components/widgets/Layout';

import Admin from './page/Admin';
import Chat from './components/admin/chat';
import Train from './components/admin/train';
import MainDashboard from './components/admin/main';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <div className="App ">
        <section>
          <div>
          <ToastContainer />
            <Routes>
            
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route path="/confirmForgetPassword" element={<ConfirmForgetPassword />} />
              <Route
                path="/live/:id"
                element={
                  <Layout>
                    <LiveChat />
                  </Layout>
                }
              />
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="demo" element={<Demo />} />
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}

              <Route path="/admin" element={<Admin />}>
                <Route path="dashboard" element={<MainDashboard />} />
                <Route path="About" element={<About />} />
                <Route path="chat" element={<Chat />} />
                <Route path="train" element={<Train />} />
                <Route path="chat/:id" element={<Chat />} />
                <Route path="widgets" element={<Home />} />
                <Route path="profile" element={<Profile />} />
                
                <Route index element={<Navigate to="dashboard" />} replace />
              </Route>
            </Routes>
          </div>
        </section>
      </div>
    </Router>
  );
}

export default App;
