import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { AiOutlineLike, AiOutlineDislike } from 'react-icons/ai';
function SingleMessage({ message, starter }) {
  const [thumbsUpSelected, setThumbsUpSelected] = useState(false);
  const [thumbsDownSelected, setThumbsDownSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [correctedMessage, setCorrectedMessage] = useState('');
  const ref = useRef();
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [message]);



  // cancel button handler

  // Click handler for thumbs-up icon
  const handleThumbsUpClick = () => {
    setThumbsUpSelected(!thumbsUpSelected);
    setThumbsDownSelected(false);
  };

  // Close modal handler
  const closeModal = () => {
    setShowModal(false);
  };

  const handleThumbsDownClick = () => {
    setThumbsDownSelected(!thumbsDownSelected);
    setThumbsUpSelected(false);
    setShowModal(true);
  };
  const handleCorrectMessage = (e) => {
    setCorrectedMessage(e.target.value);
  };



  const getTimeFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const formatter = new Intl.DateTimeFormat(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    });
    return formatter.format(date);
  };

  const userAvatars = {

    Agent: 'https://media.istockphoto.com/id/1256529118/vector/chat-bot-using-laptop-computer-robot-virtual-assistance-of-website-or-mobile-applications.jpg?s=612x612&w=0&k=20&c=F6sQrcR8ZRdJJ54AXa8XYBiDtYj_bl0CkRtv3QPncCM=',
    anonymous: 'https://img.freepik.com/free-vector/illustration-user-avatar-icon_53876-5907.jpg?w=2000',
    livechat: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=338&ext=jpg',
  };

  return (
    <>
      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-0 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    Correct the agent's response
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Agent's message: {message?.message}
                    </p>
                    <textarea
                      placeholder="Enter the correct response here"
                      className="w-full mt-2 p-2 border rounded text-gray-700"
                      value={correctedMessage}
                      onChange={(e) => setCorrectedMessage(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleCorrectMessage}
                >
                  Correct
                </button>
                <button
                  type="button"
                  className=" w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShowModal(false)}
                // ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

      )}
      <div className="relative max-h-max overflow-hidden pb-1 lg:max-h-[unset] lg:overflow-visible">

        {message?.message && (
          <>
            {starter != 0 && (
              <div className="flex items-center my-2 mt-2">
                <div className="w-[43%] border-b border-gray-600 inline-block"></div>
                <p className="w-[14%] inline-block text-xs font-normal dark:opacity-40 pb-0.5 text-gray-600 dark:text-white text-center">
                  {starter === 1 && 'Customer joined'}
                  {starter === 2 && 'Owner joined'}
                </p>
                <div className="w-[43%] border-b border-gray-600 inline-block"></div>
              </div>
            )}
            <div className="flex items-center">
              {message.name === 'Agent' && (
                <div className="flex text-xl space-x-2 ml-2">
                  <AiOutlineLike
                    onClick={handleThumbsUpClick}
                    className={`${thumbsUpSelected ? 'text-green-500' : 'text-light-1000'
                      } hover:text-green-500 transition-colors duration-200 cursor-pointer`}
                  />
                  <AiOutlineDislike onClick={handleThumbsDownClick}
                  className={`${thumbsDownSelected ? 'text-red-500' : 'text-light-1000'
                      } hover:text-red-500 transition-colors duration-200 cursor-pointer`} />
                </div>
              )}
              <div
                ref={ref}
                className={`w-full ${message?.name === 'livechat'
                  ? 'flex flex-col items-start mb-4'
                  : 'flex flex-col items-end mb-4'
                  }`}
              >
                <p className="text-xs font-normal dark:opacity-40 pb-0.5 text-gray-900 dark:text-white">
                  {' '}
                  {message.name}, {getTimeFromTimestamp(message?.timestamp)}{' '}
                </p>

                <div
                  className={`mb-2 px-3 py-2 pt-3 max-w-[60%] font-[480] text-gray-200 ${message.name === 'anonymous'
                    ? 'bg-tertiary bg-gradient-to-r from-cyan-500 to-blue-500 w-fit rounded-l-xl rounded-br-xl'
                    : message.name === 'Agent'
                      ? 'bg-gradient-to-r from-purple-400 to-purple-600 w-fit rounded-l-xl rounded-br-xl'
                      : 'bg-gray-200 dark:bg-gray-700  w-fit rounded-r-xl rounded-bl-xl'
                    }`}
                >

                  <p
                    className={`text-base pb-2 dark:text-white ${message.name === 'livechat' ? 'text-gray-700' : 'text-gray-200'
                      }`}
                  >
                    {message?.message}
                  </p>

                </div>

              </div>


              {message.name !== 'livechat' && (
                <img
                  className="w-[40px] h-[40px] rounded-full ml-2"
                  src={userAvatars[message.name]}
                  alt=""
                />
              )}
              {/* {message.name === 'livechat' && (
                <img
                  className="w-[40px] h-[40px] rounded-full ml-2"
                  src={userAvatars[message.name]}
                  alt=""
                />
              )} */}

            </div>
          </>
        )}
      </div>
    </>
  );
}

export default SingleMessage;