import React, { useEffect, useState, useRef } from 'react';
import { Widget, addResponseMessage, deleteMessages, renderCustomComponent, toggleInputDisabled, addUserMessage, toggleMsgLoader, addLinkSnippet } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import { useSearchParams } from 'react-router-dom';

import Text from '../components/elements/Text';
const Demo = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [url, setUrl] = useState("https://cdpuzmsd2wgdgoj23rvci7plmq0sesic.lambda-url.us-east-1.on.aws/conversations/")
    const [domain, setDomain] = useState(document.referrer)
    const [chatId, setChatId] = useState(searchParams.get('chatId'))
    const [userId, setUserId] = useState(searchParams.get('userId'))
    const [chatAgent, setChatAgent] = useState(searchParams.get('chatAgent'))
    const [profileIcon, setProfileIcon] = useState("https://media.istockphoto.com/id/1256529118/vector/chat-bot-using-laptop-computer-robot-virtual-assistance-of-website-or-mobile-applications.jpg?s=612x612&w=0&k=20&c=F6sQrcR8ZRdJJ54AXa8XYBiDtYj_bl0CkRtv3QPncCM=")
    const [titleIcon, setTitleIcon] = useState("https://img.freepik.com/free-vector/illustration-user-avatar-icon_53876-5907.jpg?w=2000")
    const [placeHolder, setPlaceHolder] = useState("Type a message...");
    const [showWidget, setShowWidget] = useState(false);
    const [firstToggle, setFirstToggle] = useState(true);
    const [messageSent, setMessageSent] = useState(false)
    const [isTyping, setIsTyping] = useState(false)
    const [isTypingFirst, setIsTypingFirst] = useState(true)
    const [roomId, setRoomId] = useState(localStorage.getItem("roomId", ""));
    const wsRef = useRef(null);
    const get_connect_clients_by_name = async (roomId, name) => {
        var res = await fetch(`https://api.shopagent.ai/api/room/${roomId}/clients-connected`)
        res = await res.json()
        var data = res.data || [];
        var agent_connected = data.filter(d => d.name == name)
        return agent_connected
    }
    useEffect(() => {
        // console.log('isTyping:', isTyping);
        if(!isTyping && !isTypingFirst){
            toggleMsgLoader();
        }
    }, [isTyping]);
    // Add this function to handle the state update and callback execution
    const setIsTypingAndUpdate = (value, callback) => {
        setIsTyping(value);
        callback();
    };

    // Add this function to execute the code that depends on the isTyping state update
    const afterIsTypingUpdated = () => {       
            toggleMsgLoader();
    };
    // const handleTyping = () => {   
    //     console.log(isTyping)    
    //     if(isTyping){
    //         setIsTypingAndUpdate(false, afterIsTypingUpdated);
    //     }
    // };  
    useEffect(() => {
        document.body.classList.add('dark');
        if (roomId) {

            const webSocketURL = `wss://api.shopagent.ai/api/room/${roomId}/websocket`;
            wsRef.current = new WebSocket(webSocketURL);

            wsRef.current.onopen = async () => {
                wsRef.current.send(JSON.stringify({ name: "livechat" }));
                var agent_connected = await get_connect_clients_by_name(roomId, "Agent")
                if (agent_connected.length < 1) {
                    const delay = ms => new Promise(res => setTimeout(res, ms));
                    await delay(500)
                    // wsRef.current.send(JSON.stringify({ "id": roomId }));
                }
            };

            wsRef.current.onmessage = async (event) => {
                
                const messageData = JSON.parse(event.data);
                const message = messageData.message;

                if (messageData?.quit == "Agent" || messageData?.quit == "agent") {
                    // wsRef.current.send(JSON.stringify({ "id": roomId }));
                    var agent_connected = await get_connect_clients_by_name(roomId, "anonymous")
                    if (agent_connected.length < 1) {
                        // wsRef.current.send(JSON.stringify({ "id": roomId }));
                    }
                }
                if (messageData?.quit == "anonymous" || messageData?.quit == "anonymous") {
                    // wsRef.current.send(JSON.stringify({ "id": roomId }));
                    var agent_connected = await get_connect_clients_by_name(roomId, "Agent")
                    if (agent_connected.length < 1) {
                        // wsRef.current.send(JSON.stringify({ "id": roomId }));
                    }
                }

                if (message && messageData.name !== "livechat") {
                    setIsTyping(false)
                    addResponseMessage(message);
                    // console.log(isTyping)
                    
                }
                else if (message) {
                    if (messageSent) {
                        setMessageSent(false)
                    }
                    else {
                        addUserMessage(message)

                    }
                }
            };

            wsRef.current.onclose = () => {
            };
        }

        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [roomId]);

    useEffect(() => {
        addResponseMessage("Hello! How can I help you?");
        // addUserMessage("Hello! How can I help you?");
        fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/domain?chat_bot_id=${chatId}&domain=${domain}`)
            .then(res => res.json()).then(data => {
                setShowWidget(true)

                if (data.body) {
                    setShowWidget(true)
                }
            })
    }, [])
    useEffect(() => {
        if (!showWidget) {
            const data = {
                chatAction: 'hide'
            };
            window.parent.postMessage(data, '*');
        }
        else {
            const data = {
                chatAction: 'show'
            };
            window.parent.postMessage(data, '*');
        }
    }, [showWidget])

    function handleToggle() {
        if (firstToggle) {
            setFirstToggle(false);
            if (!roomId) {
                fetch("https://api.shopagent.ai/api/room", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: userId,
                        agentId: chatId,
                    }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setRoomId(data); // Set roomId state with the received ID
                        localStorage.setItem("roomId", data)
                    })
                    .catch((error) => {
                        console.error("POST request error:", error);
                    });
            }
        }

        const data = {
            chatAction: "toggle",
        };
        window.parent.postMessage(data, "*");
    }


    const handleNewUserMessage = (newMessage) => {
        setIsTypingAndUpdate(true, afterIsTypingUpdated);
        setIsTypingFirst(false);
        setMessageSent(true)

        var body = JSON.stringify({
            "message": newMessage,
        });
        wsRef.current.send(body);

        deleteMessages(1)



    };

    return (
        <>
            <div>
                {showWidget &&
                    <Widget
                        handleNewUserMessage={handleNewUserMessage}
                        title=""
                        subtitle=""
                        // subtitle={process.env.REACT_APP_NAME}
                        profileAvatar={profileIcon}
                        profileClientAvatar={titleIcon}
                        handleToggle={handleToggle}
                        // titleAvatar={"https://cdn.shopify.com/s/files/1/0266/9900/5994/files/Logo_-_black_and_green_18e3e1d3-3141-4989-ac13-070591e9da16_1417x.png?v=1633804314"}
                        emojis={true}
                        showBadge={false}
                        showCloseButton={true}
                        senderPlaceHolder={placeHolder}
                    />
                }
            </div>
        </>
    )
}
// const CustomTimeStampFragment = (host) => {

//     const [email, setEmail] = useState('');
//     const [isEmailValid, setIsEmailValid] = useState(false);
//     const [submitButton, setSubmitButton] = useState("Submit");
//     const onEmailChange = (e) => {
//         setEmail(e.target.value);
//         setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value));
//     }
//     const onSubmit = (e) => {
//         e.preventDefault();
//         var body = JSON.stringify({
//             "EMAIL": email
//         });
//         // console.log(body)
//         fetch(host.host, {
//             "method": "POST",
//             body: body
//         })
//             .then(async res => {
//                 if (res.status == 400) {
//                     res = await res.json();
//                     return;
//                 }
//                 res = await res.json();
//                 res ? setSubmitButton("Submitted") : setSubmitButton("Failed")
//             })
//             .catch(error => {

//             });
//     }
//     return (
//         <>
//             {
//                 <section className="cc-email-input">
//                     <div className="flex justify-center px-4 sm:px-6 lg:px-8">
//                         <div className="w-full max-w-md space-y-8">
//                             <div>

//                                 <Text className="text-white text-center text-base tracking-tight text-gray-900">
//                                     <h5>Agent offline <GiNightSleep style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '2px', marginBottom: '4px' }} /> </h5>

//                                     Please submit your Email!
//                                 </Text>

//                             </div>

//                             <form className="mt-8 space-y-6" >
//                                 <div className=" space-y-6 rounded-md shadow-sm">


//                                     <div>
//                                         <label htmlFor="email-address" className="sr-only">
//                                             Email address
//                                         </label>
//                                         <input
//                                             id="email-address"
//                                             name="email"
//                                             type="email"
//                                             required
//                                             disabled={submitButton === "Submitted"}
//                                             className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
//                                             placeholder="Email address"
//                                             onChange={onEmailChange}
//                                         />
//                                         {!isEmailValid && (
//                                             <span className="text-red-500 text-xs mt-1 validation">
//                                                 Please enter a valid email address.
//                                             </span>
//                                         )}

//                                     </div>


//                                 </div>

//                                 <div>
//                                     <button
//                                         onClick={onSubmit}
//                                         disabled={submitButton === "Submitted" || !isEmailValid}
//                                         className={`${submitButton === "Submitted" && "bg-success"} ${isEmailValid && submitButton === "Submit" && "bg-info"}  group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 #`}
//                                     >
//                                         {submitButton}
//                                         {submitButton === "Submitted" &&
//                                             <TiTick style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '2px', marginTop: '2px' }} />
//                                         }

//                                     </button>
//                                 </div>

//                             </form>
//                         </div>
//                     </div>
//                 </section>
//             }
//         </>
//     )
// }
export default Demo;
