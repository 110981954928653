import LineChart from '../charts/LineChart';
import { lineChartDataSidebar } from '../chartsVariables';
import { lineChartOptionsSidebar } from '../chartsVariables';
import avatar4 from '../assets/avatar4.png';

import { SocialIcon } from 'react-social-icons';

const FreeCard = () => {
  return (
    <div className="relative flex h-full w-[240px] flex-col items-center">
      {/* <div className="flex flex-col items-center pt-6 mt-2 h-full w-full rounded-2xl bg-gradient-to-br from-cyan-600 to-blue-700">
        <h4 className="text-2xl font-semibold text-white">Video</h4>
        <p className="mt-[4px] text-xs font-medium text-white">Youtube Video Link</p>
        <div className="mt-2 flex items-center justify-center rounded-2xl bg-[#C9FBD5] py-1 px-2">
          <p className="text-xs font-semibold mb-0 text-green-500 ">+2.45%</p>
        </div>

        <div className="w-full h-full px-3 pb-3">
          <LineChart
            chartData={lineChartDataSidebar}
            chartOptions={lineChartOptionsSidebar}
          />
        </div>
      </div> */}

      {/* Sidebar profile info */}
      <div className="flex items-center justify-center gap-3 ml-2 my-2">
        <div className="">
          <h4 className="text-base font-semibold text-gray-900 dark:text-white text-center">
            Follow Us
          </h4>
          <div className='flex items-row gap-2'>
            <SocialIcon url="https://twitter.com/" />
            <SocialIcon url="https://facebook.com/in/" />
            <SocialIcon url="https://linkedin.com/in/" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeCard;
